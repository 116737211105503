import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

const ErrorPage: React.FC = () => {
  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "center",
        backgroundColor: "rgb(14, 13, 17)",
        color: "#fff",
        padding: "20px",
      }}
    >
      <Box
        style={{
          backgroundColor: "#1e1e1e",
          borderRadius: "8px",
          padding: "40px 30px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          width: "100%",
        }}
      >
        <ErrorOutline
          style={{
            fontSize: "80px",
            color: "#d32f2f",
            marginBottom: "20px",
          }}
        />
        <Typography variant="h4" component="h1" gutterBottom style={{ fontWeight: 600 }}>
          Something Went Wrong
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          Oops! An unexpected error has occurred.
        </Typography>
        <Typography variant="body1" gutterBottom style={{ color: "#bbb" }}>
          We're sorry for the inconvenience. Please try again later or return to the home page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          sx={{
            marginTop: "20px",
            padding: "10px 20px",
            fontWeight: 600,
            fontSize: "16px",
            borderRadius: "30px",
          }}
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
