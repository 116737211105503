import { FC } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Auth, Wrapper, Users, Cultures, Quotes, Tags, Authors, References, Sources, Languages } from "./containers";
import NotFoundPage from "./containers/ErrorStates/404";
import UnauthorizedPage from "./containers/ErrorStates/403";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const routes = [
  {
    path: "/",
    Component: Auth,
    authenticate: false,
  },
  {
    path: "/users",
    Component: Users,
    authenticate: true,
  },
  {
    path: "/cultures",
    Component: Cultures,
    authenticate: true,
  },
  {
    path: "/quotes",
    Component: Quotes,
    authenticate: true,
  },
  {
    path: "/quotes",
    Component: Quotes,
    authenticate: true,
  },
  {
    path: "/tags",
    Component: Tags,
    authenticate: true,
  },
  {
    path: "/authors",
    Component: Authors,
    authenticate: true,
  },
  {
    path: "/references",
    Component: References,
    authenticate: true,
  },
  {
    path: "/sources",
    Component: Sources,
    authenticate: true,
  },
  {
    path: "/languages",
    Component: Languages,
    authenticate: true,
  },
  {
    path: "/403",
    Component: UnauthorizedPage,
    authenticate: true,
  },
  {
    path: "*",
    Component: NotFoundPage,
    authenticate: true,
  },
];

const AppRoutes: FC = () => {
  const renderRouteElement = (Component: React.ComponentType, authenticate: boolean) => (
    <ErrorBoundary>
      {authenticate ? (
        <Wrapper>
          <Component />
        </Wrapper>
      ) : (
        <Component />
      )}
    </ErrorBoundary>
  );
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, Component, authenticate }) => (
          <Route key={path} path={path} element={renderRouteElement(Component, authenticate)} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
